
import React, { useContext, useEffect, useState } from 'react'

import _get from 'lodash.get'
import _forOwn from 'lodash.forown'
import ReactGA from 'react-ga'
import Countdown from 'react-countdown-now'

// components
import Vote from '../components/Vote'
import Results from '../components/Results'
import MetaTags from '../components/MetaTags'

// styled
import { Button, Container, InfoBox, TimerContainer } from '../components/styled'

// hooks
import usePost from '../hooks/usePost'
import usePostUserVote from '../hooks/usePostUserVote'

// auth
import { AuthContext } from '../auth/AuthContext'

const deleteStyle = {
  marginTop: '30px'
}

const getPostDescription = (post) => {
  let description = ''
  _forOwn(_get(post, 'candidates', {}), (candidate, key) => {
    description += `${candidate.name}? `
  })
  return description
}

const QuickPost = ({ history, match }) => {
  const postId = match.params.postulationId

  const { post: postulation, loading: postLoading, error: postError, deletePost } = usePost({ postId, isQuick: true })
  const { vote, loading: voteLoading, saveVote, abstain } = usePostUserVote({ postId, isQuick: true })

  const { user } = useContext(AuthContext)

  useEffect(() => {
    window.previousLocation = _get(history, 'location.pathname')
    ReactGA.pageview(`/quickPost${window.location.pathname}`)
  }, [match.params.postulationId])

  const handleDeletePostulation = () => {
    const doDelete = window.confirm('Are you sure you want to delete this post?')

    if (doDelete) {
      deletePost().then(() => {
        history.push('/')
      }).catch((err) => {
        window.alert('im afraid i cant do that' + err)
      })
    }
  }

  if (postLoading || voteLoading) {
    return <div>Loading</div>
  }

  if (postError) {
    return <div>{postError}</div>
  }

  const closed = postulation.isClosed
  const expired = postulation.isExpired

  return (
    <Container className='App'>
      <MetaTags
        title={`Sortada.com: ${_get(postulation, 'title', '')}`}
        description={getPostDescription(postulation)}
        image={postulation.imgUrl}
      />
      {!closed && !expired && <InfoBox>Welcome to Sortada. You can vote on this quick post without logging in.</InfoBox>}
      {postulation.hasExpiration && <TimerContainer><span>Time left: </span><Countdown date={postulation.expires} /></TimerContainer>}
      <h1>{postulation.title}</h1>
      {postulation.id &&
            !vote && !closed && !expired &&
              <Vote isQuick postulation={postulation} vote={vote} saveVote={saveVote} abstain={abstain} deleteFn={handleDeletePostulation} />}
      {closed && !vote && <InfoBox>You're late to the party.  This postulation was closed before you could vote...here's the results</InfoBox>}
      {expired && !vote && <InfoBox>Ooops.  This quick post is over....here's the results</InfoBox>}
      {(vote || closed || expired) && <Results vote={vote} postulation={postulation} />}
      {_get(user, 'uid') === postulation.ownerId && <Button style={deleteStyle} onClick={handleDeletePostulation}>Delete</Button>}
    </Container>
  )
}

export default QuickPost
